*{
  margin: 0; padding: 0; box-sizing: border-box;
  transition: all 0.3s ease-in-out; 
}

.hide{
  display: none !important;
}
html,body{
  overscroll-behavior: none;
  touch-action: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px 20px;
  user-select: none;
  /* max-width: 512px; */
  margin: auto;
}

@media screen and (max-width: 380px) {
  body{
    padding: 18px 10px;
  }
}
.no-desktop{
 display: none;
}
@media screen and (min-width: 800px) {
  .no-desktop{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   background-color: #fff;
   position: fixed;
   top: 0;left: 0;
   width: 100%;
   height: 100dvh;
   z-index: 1000;
   font-size: 1.2rem;
   text-align: center;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    filter: saturate();
}

#loader-wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

#loader-wrapper::before {
  content: "";
  position: absolute;
  z-index: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: .6
}

#loader-wrapper li-icon {
  width: 100%;
  height: 100%
}


li-icon[type="linkedin-bug"] .background {
  fill: #000000
}

li-icon[type="linkedin-bug"][color="brand"] .background {
  fill: #0077B5
}

li-icon[type="linkedin-bug"][color="inverse"] .background {
  fill: #ffffff
}

li-icon[type="linkedin-bug"][color="premium"] .background {
  fill: #AF9B62
}

.artdeco-premium-bug-variant li-icon[type="linkedin-bug"][color="premium"] .background {
  fill: #EFB920
}

li-icon[type="linkedin-bug"] .bug-text-color {
  display: none
}

li-icon[type="linkedin-bug"][color] .bug-text-color {
  display: block
}

li-icon[type="linkedin-bug"][color="inverse"] .bug-text-color {
  display: none
}

li-icon[type="linkedin-bug"][size="14dp"] {
  width: 14px;
  height: 14px
}

li-icon[type="linkedin-bug"][size="21dp"] {
  width: 21px;
  height: 21px
}

li-icon[type="linkedin-bug"][size="28dp"] {
  width: 28px;
  height: 28px
}

li-icon[type="linkedin-bug"][size="34dp"] {
  width: 34px;
  height: 34px
}

li-icon[type="linkedin-bug"][size="40dp"] {
  width: 40px;
  height: 40px
}

li-icon[type="linkedin-bug"][size="48dp"] {
  width: 48px;
  height: 48px
}

li-icon[type="linkedin-bug"] svg {
  width: 100%;
  height: 100%
}

li-icon[type="linkedin-bug"] .bug-14dp,
li-icon[type="linkedin-bug"] .bug-21dp,
li-icon[type="linkedin-bug"] .bug-28dp,
li-icon[type="linkedin-bug"] .bug-34dp,
li-icon[type="linkedin-bug"] .bug-40dp,
li-icon[type="linkedin-bug"] .bug-48dp {
  display: none
}

li-icon[type="linkedin-bug"][size="14dp"] .bug-14dp,
li-icon[type="linkedin-bug"][size="21dp"] .bug-21dp,
li-icon[type="linkedin-bug"][size="28dp"] .bug-28dp,
li-icon[type="linkedin-bug"][size="34dp"] .bug-34dp,
li-icon[type="linkedin-bug"][size="40dp"] .bug-40dp,
li-icon[type="linkedin-bug"][size="48dp"] .bug-48dp {
  display: block
}

li-icon[type="linkedin-bug"] .dpi-gt1 {
  display: block
}

li-icon[type="linkedin-bug"] .dpi-1 {
  display: none
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 96dpi),
(max-resolution: 1dppx) {
  li-icon[type="linkedin-bug"] dpi-gt1 {
      display: none
  }

  li-icon[type="linkedin-bug"] .dpi-1 {
      display: block
  }
}

li-icon[type="linkedin-logo"] .background {
  fill: #000000
}

@media screen and (-ms-high-contrast:active) {
  li-icon[type="linkedin-logo"] .background {
      fill: buttonText
  }
}

@media screen and (-ms-high-contrast:active) {

  li-icon[type="linkedin-logo"]:not([color="brand"]) .linkedin-text path,
  li-icon[type="linkedin-logo"]:not([color="brand"]) .linkedin-text polygon {
      fill: buttonText
  }
}

li-icon[type="linkedin-logo"][color="brand"] .background {
  fill: #0077B5
}

@media screen and (-ms-high-contrast:active) {

  li-icon[type="linkedin-logo"][color="brand"] .linkedin-text path,
  li-icon[type="linkedin-logo"][color="brand"] .linkedin-text polygon {
      fill: buttonText
  }
}

li-icon[type="linkedin-logo"][color="inverse"] .background {
  fill: #ffffff
}

@media screen and (-ms-high-contrast:active) {
  li-icon[type="linkedin-logo"][color="inverse"] .background {
      fill: buttonText
  }
}

li-icon[type="linkedin-logo"][color="inverse"] .linkedin-text path,
li-icon[type="linkedin-logo"][color="inverse"] .linkedin-text polygon,
li-icon[type="linkedin-logo"].logo-lockup-inverse .linkedin-text path,
li-icon[type="linkedin-logo"].logo-lockup-inverse .linkedin-text polygon {
  fill: #FFFFFF
}

@media screen and (-ms-high-contrast:active) {

  li-icon[type="linkedin-logo"][color="inverse"] .linkedin-text path,
  li-icon[type="linkedin-logo"][color="inverse"] .linkedin-text polygon,
  li-icon[type="linkedin-logo"].logo-lockup-inverse .linkedin-text path,
  li-icon[type="linkedin-logo"].logo-lockup-inverse .linkedin-text polygon {
      fill: buttonText
  }
}

li-icon[type="linkedin-logo"][color="premium"] .background {
  fill: #AF9B62
}

@media screen and (-ms-high-contrast:active) {
  li-icon[type="linkedin-logo"][color="premium"] .bug-text-color {
      fill: black
  }
}

.artdeco-premium-bug-variant li-icon[type="linkedin-logo"][color="premium"] .background {
  fill: #EFB920
}

@media screen and (-ms-high-contrast:active) {
  .artdeco-premium-bug-variant li-icon[type="linkedin-logo"][color="premium"] .background {
      fill: buttonText
  }
}

li-icon[type="linkedin-logo"] .bug-text-color {
  display: none
}

li-icon[type="linkedin-logo"][color] .bug-text-color {
  display: block
}

li-icon[type="linkedin-logo"][color="inverse"] .bug-text-color {
  display: none
}

li-icon[type="linkedin-logo"][size="14dp"] {
  width: 56px;
  height: 14px
}

li-icon[type="linkedin-logo"][size="21dp"] {
  width: 84px;
  height: 21px
}

li-icon[type="linkedin-logo"][size="28dp"] {
  width: 110px;
  height: 28px
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi),
(min-resolution: 2dppx) {
  li-icon[type="linkedin-logo"][size="28dp"] {
      width: 112px
  }
}

li-icon[type="linkedin-logo"][size="34dp"] {
  width: 135px;
  height: 34px
}

li-icon[type="linkedin-logo"][size="40dp"] {
  width: 159px;
  height: 40px
}

li-icon[type="linkedin-logo"][size="48dp"] {
  width: 191px;
  height: 48px
}

li-icon[type="linkedin-logo"] svg {
  width: 100%;
  height: 100%
}

li-icon[type="linkedin-logo"] .logo-14dp,
li-icon[type="linkedin-logo"] .logo-21dp,
li-icon[type="linkedin-logo"] .logo-28dp,
li-icon[type="linkedin-logo"] .logo-34dp,
li-icon[type="linkedin-logo"] .logo-40dp,
li-icon[type="linkedin-logo"] .logo-48dp {
  display: none
}

li-icon[type="linkedin-logo"][size="14dp"] .logo-14dp,
li-icon[type="linkedin-logo"][size="21dp"] .logo-21dp,
li-icon[type="linkedin-logo"][size="28dp"] .logo-28dp,
li-icon[type="linkedin-logo"][size="34dp"] .logo-34dp,
li-icon[type="linkedin-logo"][size="40dp"] .logo-40dp,
li-icon[type="linkedin-logo"][size="48dp"] .logo-48dp {
  display: block
}

li-icon[type="linkedin-logo"] .dpi-gt1 {
  display: block
}

li-icon[type="linkedin-logo"] .dpi-1 {
  display: none
}

@media (-webkit-max-device-pixel-ratio: 1),
(max-resolution: 96dpi),
(max-resolution: 1dppx) {
  li-icon[type="linkedin-logo"] .dpi-gt1 {
      display: none
  }

  li-icon[type="linkedin-logo"] .dpi-1 {
      display: block
  }
}

@-webkit-keyframes pebbleDraw {
  to {
      stroke-dashoffset: 0
  }
}

@keyframes pebbleDraw {
  to {
      stroke-dashoffset: 0
  }
}

@-webkit-keyframes pebbleGrow {
  to {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes pebbleGrow {
  to {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

use {
  fill-opacity: 1;
  stroke-opacity: 1;
  fill: transparent;
  stroke: transparent
}

use[data-size=small],
use[data-state=active],
.active use[ * |href^="#nav-"] {
  fill-opacity: 0;
  stroke-opacity: 0;
  fill: currentColor;
  stroke: currentColor
}

li-icon[type=loader] {
  color: #0084bf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

li-icon[type=loader] .artdeco-spinner {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: visible
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:before {
  content: '';
  display: block;
  width: 0;
  margin: -4px auto;
  height: 14px;
  border: 1px solid currentColor;
  border-radius: 1px;
  -webkit-animation: loaderfade 1000ms infinite ease-in-out both;
  animation: loaderfade 1000ms infinite ease-in-out both
}

.blink{
  -webkit-animation: loaderfade 1000ms infinite ease-in-out both;
  animation: loaderfade 1000ms infinite ease-in-out both
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(12) {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(12) {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(12):before {
  -webkit-animation-delay: -1000ms;
  animation-delay: -1000ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(11) {
  -webkit-transform: rotate(-330deg);
  transform: rotate(-330deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(11) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(11):before {
  -webkit-animation-delay: -916.66667ms;
  animation-delay: -916.66667ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(10) {
  -webkit-transform: rotate(-300deg);
  transform: rotate(-300deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(10) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(10):before {
  -webkit-animation-delay: -833.33333ms;
  animation-delay: -833.33333ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(9) {
  -webkit-transform: rotate(-270deg);
  transform: rotate(-270deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(9) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(9):before {
  -webkit-animation-delay: -750ms;
  animation-delay: -750ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(8) {
  -webkit-transform: rotate(-240deg);
  transform: rotate(-240deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(8) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(8):before {
  -webkit-animation-delay: -666.66667ms;
  animation-delay: -666.66667ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(7) {
  -webkit-transform: rotate(-210deg);
  transform: rotate(-210deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(7) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(7):before {
  -webkit-animation-delay: -583.33333ms;
  animation-delay: -583.33333ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(6) {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(6) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(6):before {
  -webkit-animation-delay: -500ms;
  animation-delay: -500ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(5) {
  -webkit-transform: rotate(-150deg);
  transform: rotate(-150deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(5) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(5):before {
  -webkit-animation-delay: -416.66667ms;
  animation-delay: -416.66667ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(4) {
  -webkit-transform: rotate(-120deg);
  transform: rotate(-120deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(4) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(4):before {
  -webkit-animation-delay: -333.33333ms;
  animation-delay: -333.33333ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(3) {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(3):before {
  -webkit-animation-delay: -250ms;
  animation-delay: -250ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(2) {
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(2):before {
  -webkit-animation-delay: -166.66667ms;
  animation-delay: -166.66667ms
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(1) {
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg)
}

[dir="rtl"] li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg)
}

li-icon[type=loader] .artdeco-spinner .artdeco-spinner-bars:nth-child(1):before {
  -webkit-animation-delay: -83.33333ms;
  animation-delay: -83.33333ms
}

li-icon[type=loader][size=small] .artdeco-spinner-bars:before {
  height: 6px;
  margin: 10px auto
}

@-webkit-keyframes loaderfade {

  0%,
  5%,
  100% {
      opacity: 0.8
  }

  95% {
      opacity: 0.05
  }
}

@keyframes loaderfade {

  0%,
  5%,
  100% {
      opacity: 0.8
  }

  95% {
      opacity: 0.05
  }
}

li-icon {
  display: inline-block;
  width: 300px;
  height: 150px;
  overflow: hidden;
  position: relative
}

li-icon>svg {
  display: block;
  margin: auto
}

li-icon[type$="-icon"] {
  width: 24px;
  height: 24px
}

li-icon[type$="-icon"][size="small"] {
  width: 16px;
  height: 16px
}

li-icon[type$="-icon"]>svg {
  vertical-align: top
}

li-icon[type^="nav-"] {
  width: 32px !important;
  height: 32px !important
}

li-icon .inactive-item,
li-icon .large-icon {
  fill: currentColor
}

li-icon .active-item,
li-icon .small-icon {
  fill: currentColor;
  visibility: hidden
}

li-icon[size=small] .inactive-item,
li-icon[size=small] .large-icon,
li-icon[type^="nav-"][active] .inactive-item,
li-icon[type^="nav-"][active] .large-icon {
  visibility: hidden
}

li-icon[size=small] .active-item,
li-icon[size=small] .small-icon,
li-icon[type^="nav-"][active] .active-item,
li-icon[type^="nav-"][active] .small-icon {
  visibility: visible
}

li-icon[type^="app-"] {
  width: 40px !important;
  height: 40px !important
}

li-icon[type=loader] {
  fill-opacity: 1;
  stroke-opacity: 1;
  fill: transparent;
  stroke: transparent
}

li-icon[type="loader"][size="small"] {
  fill-opacity: 0;
  stroke-opacity: 0;
  fill: currentColor;
  stroke: currentColor
}

li-icon .color-icon {
  display: none
}

li-icon[color] .solid-icon {
  display: none
}

li-icon[color] .color-icon {
  display: block
}

li-icon[type^="large-"],
li-icon[type^="large-"][size="small"] {
  width: 48px !important;
  height: 48px !important
}

[dir=rtl] li-icon[type *=arrow],
[dir=rtl] li-icon[type *=chevron],
[dir=rtl] li-icon[type=to-start-icon],
[dir=rtl] li-icon[type=to-end-icon],
[dir=rtl] li-icon[type=share-ios-icon],
[dir=rtl] li-icon[type=question-pebble-icon],
[dir=rtl] li-icon[type=forward-icon],
[dir=rtl] li-icon[type=leave-icon],
[dir=rtl] li-icon[type *=follow],
[dir=rtl] li-icon[type=reply-icon],
[dir=rtl] li-icon[type=enter-icon],
[dir=rtl] li-icon[type=share-linkedin-icon] {
  /*!rtl:begin:ignore*/
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  /*!rtl:end:ignore*/
}

[dir=rtl] li-icon>svg {
  /*!rtl:begin:ignore*/
  float: left;
  /*!rtl:end:ignore*/
}

li-icon[type$="-pebble-icon"][animate] {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  -webkit-animation: pebbleGrow 334ms ease-in-out forwards;
  animation: pebbleGrow 334ms ease-in-out forwards
}

li-icon[type$="-pebble-icon"][animate] .circle {
  stroke-dasharray: 63;
  stroke-dashoffset: 63;
  -webkit-animation: pebbleDraw 500ms ease-out forwards;
  animation: pebbleDraw 500ms ease-out forwards;
  -webkit-animation-delay: 334ms;
  animation-delay: 334ms
}

li-icon[type$="-pebble-icon"][animate][size="small"] .circle {
  stroke-dasharray: 38;
  stroke-dashoffset: 38
}

li-icon[type^="premium-"] {
  width: auto;
  height: 16px
}

li-icon[type^="premium-"][type^="premium-app-icon"],
li-icon[type^="premium-"][type^="premium-inverse-app"] {
  height: 24px
}

li-icon[type^="premium-"][size="8dp"] {
  height: 8px
}

li-icon[type^="premium-"][size="10dp"] {
  height: 10px
}

li-icon[type^="premium-"][size="12dp"] {
  height: 12px
}

li-icon[type^="premium-"][size="16dp"] {
  height: 16px
}

li-icon[type^="premium-"][size="20dp"] {
  height: 20px
}

li-icon[type^="premium-"][size="24dp"] {
  height: 24px
}

li-icon[type^="premium-"][size="32dp"] {
  height: 32px
}

li-icon[type^="premium-"] text {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 4px;
  dominant-baseline: text-before-edge;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif
}

@media screen and (-ms-high-contrast: white-on-black) {

  li-icon[type="premium-badge"],
  li-icon[type="premium-app-icon"] {
      -ms-high-contrast-adjust: none;
      background-color: #fff
  }

  li-icon[type="premium-badge"] svg,
  li-icon[type="premium-app-icon"] svg {
      height: 90% !important;
      position: relative;
      top: 5%;
      left: 2%
  }
}

@media screen and (-ms-high-contrast: black-on-white) {

  li-icon[type="premium-inverse-badge"],
  li-icon[type="premium-inverse-app-icon"] {
      -ms-high-contrast-adjust: none;
      background-color: #000
  }

  li-icon[type="premium-inverse-badge"] svg,
  li-icon[type="premium-inverse-app-icon"] svg {
      height: 90% !important;
      position: relative;
      top: 5%;
      left: 2%
  }
}
